<template>
  <b-button
      size="sm"
      variant="info"
      class="mr-1"
      @click.stop="copyEntity(id)"
  >
    <feather-icon
        icon="CopyIcon"
    />
  </b-button>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  name: "copyEntity",
  components: {
    BRow,
    BCol,
    BButton,
  },
  methods: {
    copyEntity() {
      this.$bvModal
          .msgBoxConfirm('Szeretnéd átmásolni az entitáshoz feltöltött fájlokat, képeket, videókat is?', {
            title: '',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Igen',
            cancelTitle: 'Nem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            let copyFiles = false;
            if (value) {
              copyFiles = true;
            }

            this.$store.dispatch('copyEntity', {
              id: this.id,
              type: this.type,
              copyFiles: copyFiles
            }).then(async response => {
              await this.$router.push({name: 'modify-'+this.type, params: {id: response.data.entity.id}})
              this.$helpers.showSuccessToast()
            }).catch(error => {
              if (error.response.status === 422) {
                this.$refs.simpleRules.setErrors(error.response.data.errors)
              } else {
                this.$helpers.showErrorToast(error.toString())
              }
            })

          })
    }
  }
}
</script>

<style scoped>

</style>
